<template>
    <section class="h-100">
        <div id="menu" class="row mx-0 f-15 border-bottom px-2 align-items-center" style="height:45px;">
            <div
            v-if="(is_kit || tabsEnKits)"
            class="h-100 col d-middle-center cr-pointer"
            :class="tab=='productos'? 'border-b-black text-general':''"
            @click="setTab('productos')"
            >
                Productos
            </div>
            <div
            class="h-100 col d-middle-center cr-pointer"
            :class="tab=='registro'? 'border-b-black text-general':''"
            @click="setTab('registro')"
            >
                Registro
            </div>
            <div
            v-if="tabsEnKits"
            class="h-100 col d-middle-center cr-pointer"
            :class="tab=='estadisticas'? 'border-b-black text-general':''"
            @click="setTab('estadisticas')"
            >
                Estadísticas
            </div>
            <div v-if="!showTabStocks && (tabEnCedis || tabsEnProductos)"
                 class="h-100 col d-middle-center cr-pointer"
                 :class="tab=='Cedis'? 'border-b-black text-general':''"
                 @click="setTab('Cedis')"
            >
                Cedis
            </div>
            <div v-if="showTabStocks && (tabEnCedis || tabsEnProductos) && $can('tab2_ver_cedis_productos_stock')"
                 class="h-100 col d-middle-center cr-pointer"
                 :class="tab=='stock'? 'border-b-black text-general':''"
                 @click="setTab(tab='stock')"
            >
                Stock
            </div>
            <div v-if="(tabEnCedis || tabsEnProductos)"
                 class="h-100 col d-middle-center cr-pointer"
                 :class="tab=='comentarios'? 'border-b-black text-general':''"
                 @click="setTab('comentarios')"
            >
                Comentarios
            </div>
        </div>
        <registro v-show="id_producto && tab == 'registro'" :class="{'disabled-div':!edicionDisponible}" />
        <div v-show="id_producto && tab == 'comentarios'" style="height:calc(100vh - 230px);">
            <comentario :id="id_producto" ref="comentarios" :modelo="2" />
        </div>
        <cedis v-show="tab == 'Cedis'" />

        <stock v-show="tab == 'stock' && showTabStocks" :show-tab-stocks="tab == 'stock'" />
        <productosKits v-show="tab == 'productos'" ref="productos_kit" :class="{'disabled-div':!edicionDisponible}" />
        <estadisticas v-if="tab == 'estadisticas'" ref="estadisticas" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    components: {
        registro: () => import('./registro'),
        cedis: () => import('./cedis'),
        stock: () => import('./stock'),
        productosKits : () => import('./productosKits'),
        estadisticas : () => import('./estadisticas')
    },
    data(){
        return {
            tab: 'registro',
            showTabStocks: false,
            tabEnCedis : false,
            tabsEnKits : false,
            tabsEnProductos : false,
            activeName: 'first',
        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
            is_kit: 'productos/productos/is_kit',
            infoCedis:'cedis/info_cedis',
        }),
        edicionDisponible(){
            return this.infoCedis.id != null && this.infoCedis.id_cedis_catalogo === null
        }
    },

    mounted(){
        this.verificarshowTabStocks()
        this.tabsEnCedis()
        this.tabsEnKitss()
        this.tabsEnProductoss()
    },
    methods:{
        verificarshowTabStocks(){
            let condicion = this.$route.name === 'admin.cedis.productos' || this.$route.name === 'tendero.productos'
            this.showTabStocks =  condicion
        },
        setTab(tab){
            this.tab = tab
            if(this.tab == 'productos'){
                this.$refs.productos_kit.obtenerProductosDelKit()
            }
            if(this.tab == 'estadisticas'){
                this.$refs.estadisticas.ajustar_graficas()
            }
        },
        tabsEnCedis(){
            let condicion = this.$route.name === 'admin.cedis.productos' || this.$route.name === 'tendero.productos'
            this.tabEnCedis = condicion
        },
        tabsEnKitss(){
            let condicion = this.$route.name === 'admin.cedis.combos-kits'
            this.tabsEnKits = condicion
        },
        tabsEnProductoss(){
            let condicion = this.$route.name === 'admin.productos'
            this.tabsEnProductos = condicion
        }
    }
}
</script>
<style lang="scss" scoped>
</style>
